import { combineReducers } from 'redux';
import settingsReducer from './slices/settings.slice.js';
import themesReducer from './slices/themes.slice'
import userReducer from './slices/user.slice'
import productReducer from './slices/product.slice';
import serverConfigReducer from './slices/serverConfig.slice';
import notificationReducer from './slices/notification.slice';



export default combineReducers({
    //local
    theme: themesReducer,
    settings: settingsReducer,
    //configs
    serverConfig: serverConfigReducer,
    product: productReducer,
    //general
    notification: notificationReducer,
    //user
    user: userReducer,
});

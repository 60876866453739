import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Nav, NavbarBrand, NavItem } from "reactstrap";
import { toggleSetting } from "store/actions";
import {
    isAuthenticate
} from "store/selectors";
import NotificationsMenu from "../Notifications/NotificationsMenu";
import HeaderRun from "./Header.run";
import ProductBrand from "./ProductBrand";
import SidebarUserBlock from "./SidebarUserBlock";

const Header = () => {
    useEffect(() => {
        HeaderRun();
    }, []);
    const dispatch = useDispatch()
    const isAuth = useSelector(isAuthenticate);
    const emailVerified = !!useSelector(
        (state) => state.user.info.emailVerified
    );

    const toggle = (e) => {
        e.preventDefault();
        dispatch(toggleSetting('asideToggled'));
    };
    const { t } = useTranslation();


    return (
        <header className="topnavbar-wrapper">
            {isAuth && !emailVerified && (
                <div className="top-notif-message d-none">
                    {t("Please confirm your email to use all features")}
                </div>
            )}




            <nav className="navbar topnavbar d-flex align-items-center">

                <button onClick={toggle} className="d-md-none navbar-toggler collapsed" id="toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <NavbarBrand tag={'div'} style={{ cursor: 'default' }} className="d-flex">
                    <Link to={"/"}>
                        <ProductBrand forHeader={true}></ProductBrand>
                    </Link>
                </NavbarBrand>
                <div className="d-flex flex-row justify-content-between order-2 order-lg-3 text-dark">
                    <Nav navbar className="flex-row align-items-md-center">
                        { /* START Left navbar */}

                        <div className="d-none d-md-block">
                            {/* <NotificationsMenu></NotificationsMenu> */}
                        </div>

                        <NavItem>
                            <div className="">
                                <SidebarUserBlock></SidebarUserBlock>
                            </div>
                        </NavItem>
                    </Nav>

                </div>
            </nav>
            {/* END Top Navbar */}
        </header>
    );
};

export default Header;

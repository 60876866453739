import { Classes, Popover2 } from "@blueprintjs/popover2";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { ReactComponent as AccountSettingsIcon } from "icons/account-settings-icon.svg";
import { ReactComponent as ChangePasswordIcon } from "icons/change-password-icon.svg";
import { ReactComponent as FeedbackIcon } from "icons/feedback-icon.svg";
import { ReactComponent as LogoutIcon } from "icons/logout-icon.svg";
import { ReactComponent as SupportIcon } from "icons/support-icon.svg";
import { ReactComponent as ChangeOrganizationIcon } from "icons/change-organization-icon.svg";
import { useAppSelector } from "store/store";
import ContactUsForm from "./ContactUsForm";
import UserAvatar from "../Common/UserAvatar";
import FeedBackForm from "./FeedBackForm";


const SidebarUserBlock = () => {

    const userOrganizations = useAppSelector(
        (state) => state.user.organizations
    );
    const userInfo = useAppSelector((a) => a.user.info);

    const [contactUsType, setContactUsType] = useState("support");
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showFeedBackModal, setShowFeedBackModal] = useState(false);

    const nameEn = userInfo ? userInfo.name : "";
    const userEmail = userInfo ? userInfo.email : "";

    const Content = () => {
        return (
            <div className="d-flex flex-column align-items-center">

                <div className="align-self-center text-center d-flex flex-column justify-content-center align-items-center px-3 py-1">
                    <UserAvatar
                        src={userInfo.photo}
                        name={userInfo.name}
                        size={80}
                        round={true}
                    />
                    <br />
                    <span className="pt-1 user-block-name"> {nameEn} </span>
                    <span className="pt-1 user-block-email"> {userEmail} </span>
                </div>
                <div>
                    {/* <div>
                        <Link to="/account/accountSetting">
                            <Button
                                className={`fs-sm-12 py-2 px-0 ${Classes.POPOVER2_DISMISS}`}
                                color="link"
                            >
                                <AccountSettingsIcon />

                                <span className="m-start-1 text-bold">
                                    <Trans i18nKey="account"></Trans>
                                </span>
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Link to="/account/changePassword">
                            <Button
                                className={`fs-sm-12 py-2 px-0 ${Classes.POPOVER2_DISMISS}`}
                                color="link"
                            >
                                <ChangePasswordIcon />

                                <span className="m-start-1 text-bold">
                                    {userInfo.hasPassword && <Trans i18nKey="Change password"></Trans>}
                                    {!userInfo.hasPassword && <Trans i18nKey="Set password"></Trans>}
                                </span>
                            </Button>
                        </Link>
                    </div>

                    <hr className='divider-short' /> */}

                    {/* <div>
                        <Button
                            className="py-2 px-0 fs-sm-12"
                            color="link"
                            onClick={() => { setContactUsType('support'); setShowContactUsModal(true) }}
                        >
                            <SupportIcon />
                            <span className="m-start-1 text-bold">
                                <Trans i18nKey="SUPPORT"></Trans>
                            </span>
                        </Button>
                    </div>
                    <div>
                        <Button
                            className="py-2 px-0 fs-sm-12"
                            color="link"
                            onClick={() => { setContactUsType('feedback'); setShowFeedBackModal(true) }}
                        >
                            <FeedbackIcon />
                            <span className="m-start-1 text-bold">
                                <Trans i18nKey="FEEDBACK"></Trans>
                            </span>
                        </Button>
                    </div> */}


                    {/* <hr className='divider-short' /> */}
                    {/* {userOrganizations.length > 1 && <div>
                        <Link to="/organization/select">
                            <Button
                                className={`fs-sm-12 mt-1 py-2 px-0 ${Classes.POPOVER2_DISMISS}`}
                                color="link"
                            >
                                <ChangeOrganizationIcon />
                                <span className="m-start-1 text-bold">
                                    Change Organization
                                </span>
                            </Button>
                        </Link>
                        <hr className='divider-short' />
                    </div>
                    } */}
                    <div >
                        <Link to="/logout">
                            <Button
                                className={`fs-sm-12 mt-1 py-2 px-0 ${Classes.POPOVER2_DISMISS}`}
                                color="link"
                            >
                                <LogoutIcon />
                                <span className="m-start-1 text-bold">
                                    <Trans i18nKey="logout"></Trans>
                                </span>
                            </Button>
                        </Link>
                    </div>

                    <div className="terms-block">
                        <a rel="noopener noreferrer" href='https://www.teamsuite.com/privacy' target='_blank'>
                            <Trans i18nKey="Privacy Policy"></Trans>
                        </a>
                        <span>&nbsp; • &nbsp;</span>
                        <a rel="noopener noreferrer" href='https://www.teamsuite.com/terms' target='_blank'>
                            <Trans i18nKey="Terms of Service"></Trans>
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    function UserDropDownToggle() {
        const nameSplits = nameEn.split(' ');
        const nameAbr = nameSplits.length > 1 ? nameSplits[0] + " " + nameSplits[nameSplits.length - 1][0] + "." : nameEn;
        return (
            <>
                <Popover2
                    minimal={true}
                    interactionKind="click"
                    popoverClassName={`${Classes.POPOVER2_CONTENT_SIZING} userDropDown`}
                    placement="bottom"
                    content={<Content></Content>}
                    renderTarget={({
                        isOpen,
                        ref,
                        ...targetProps
                    }: {
                        ref: any;
                        isOpen: boolean;
                    }) => (
                        <div
                            {...targetProps}
                            ref={ref}
                            className="user-block user-block-info d-flex justify-content-between align-items-center"
                        >
                            {/* <ImageWithoutCaching className="rounded-circle" src={userInfo.photo} alt="Avatar" style={{
                                width: "35px", height: "35px"
                            }} /> */}
                            <UserAvatar
                                src={userInfo.photo}
                                name={userInfo.name}
                                size={35}
                                round={true}
                            />
                            <div className="mx-1 mt-1 d-none d-md-block">
                                <div style={{ lineHeight: 1.3 }}>
                                    <span className="user-block-name mx-2 d-none d-md-inline">
                                        <span>{nameAbr}</span>
                                    </span>
                                </div>

                            </div>
                            &nbsp;&nbsp;
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.46967 5.46967C3.76256 5.17678 4.23744 5.17678 4.53033 5.46967L6.26759 7.20693C6.67236 7.6117 6.94445 7.88297 7.17076 8.07509C7.39038 8.26153 7.51706 8.32899 7.61373 8.36039C7.86478 8.44197 8.13522 8.44197 8.38627 8.36039C8.48294 8.32899 8.60962 8.26153 8.82924 8.07509C9.05555 7.88296 9.32764 7.6117 9.73241 7.20693L11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967C12.8232 5.76256 12.8232 6.23744 12.5303 6.53033L10.7931 8.26759L10.7716 8.28911C10.3939 8.66682 10.0786 8.98209 9.8 9.2186C9.50944 9.46527 9.20979 9.67001 8.8498 9.78698C8.29748 9.96644 7.70252 9.96644 7.1502 9.78698C6.79021 9.67001 6.49056 9.46527 6.2 9.2186C5.9214 8.98209 5.60615 8.66682 5.22845 8.28911L5.20693 8.26759L3.46967 6.53033C3.17678 6.23744 3.17678 5.76256 3.46967 5.46967Z"
                                    fill="white"
                                />
                            </svg>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                    )}
                />
                <ContactUsForm
                    formType={contactUsType}
                    isOpen={showContactUsModal}
                    setIsOpen={setShowContactUsModal}
                />
                <FeedBackForm
                    formType={contactUsType}
                    isOpen={showFeedBackModal}
                    setIsOpen={setShowFeedBackModal}
                />
            </>
        );
    }

    return (
        <div className="">
            <UserDropDownToggle></UserDropDownToggle>
        </div>
    );
};

export default SidebarUserBlock;

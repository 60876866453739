// GLOBAL CONSTANTS
// -----------------------------------

import { camelCase } from "lodash";
import React from 'react';
import { Trans } from "react-i18next";

export const APP_COLORS = {
    'primary': 'var(--primary)',
    'success': '#27c24c',
    'info': '#23b7e5',
    'warning': '#ff902b',
    'danger': '#f05050',
    'inverse': '#131e26',
    'green': '#37bc9b',
    'pink': '#f532e5',
    'purple': '#7266ba',
    'dark': '#3a3f51',
    'yellow': '#fad732',
    'gray-darker': '#232735',
    'gray-dark': '#3a3f51',
    'gray': '#dde6e9',
    'gray-light': '#e4eaec',
    'gray-lighter': '#edf1f2'
};

export const APP_MEDIAQUERY = {
    'desktopLG': 1200,
    'desktop': 992,
    'tablet': 768,
    'mobile': 480
};

export class defaultAvatar {
    static defaultAvatars = {
        ORGANIZATION: 'uploads/organizationAvatar.jpg',
        PROJECT: 'uploads/projectAvatar.jpg',
        USER: 'uploads/defaultAvatar.jpg'
    }

    static IsDefaultAvatar(address) {
        return address === defaultAvatar.defaultAvatars.ORGANIZATION ||
            address === defaultAvatar.defaultAvatars.PROJECT ||
            address === defaultAvatar.defaultAvatars.USER
    }
}

export const SubscriptionTypes = {
    Free: 'free',
    Standard: 'standard',
    Trial: 'trial',
    Paid: 'paid',
    UnPaid: 'unpaid',
    Expired: 'expired',
    Cancelled: 'cancelled',
    IS_PROCESSING: 'is_processing',
    PAYMENT_ACTION_REQUIRED: 'payment_action_required',
}
export const PermissionsList = {
    ORGANIZATION_SUBSCRIPTION_VIEW: 'organization-subscription-view',
    ORGANIZATION_SETTING_UPADTE: 'organization-setting-update',
    ORGANIZATION_USERMANAGEMENT_VIEW: 'organization-usermanagement-view',
    ORGANIZATION_USERMANAGEMENT_ROLE: 'organization-usermanagement-role',
    ORGANIZATION_USERMANAGEMENT_TEAM: 'organization-usermanagement-view',// team
    TIME_PROJECT_VIEW: 'time-projects-view',
    TIME_PROJECT_VIEWALL: 'time-projects-viewall',
    TIME_PROJECT_CREATE: 'time-projects-create',
    TIME_PROJECT_UPDATE: 'time-projects-update',
    TIME_MEMBER_VIEW: 'time-member-view',
    TIME_MEMBER_UPDATE: 'time-member-update',
    TIME_MYWORKDAIRY_VIEW: 'time-mytime-view',
    TIME_MYWORKDAIRY_ADDMANUAL: 'time-mytime-addMaual',
    TIME_DOWNLOAD_VIEW: 'time-download-view',
    OKR_GOALS_VIEW: 'goals-goal-view',
    OKR_GOALS_CREATE: 'goals-goal-create',
}

export const OrganizationProppertiesDefinitions = {
    TIMETRACKER_SCREENSHOT_ENABLED: "TIMETRACKER_SCREENSHOT_ENABLED"
}
export const OKR_Statuses = [
    {
        name: <Trans i18nKey="On Track"></Trans>,
        slug: "ontrack",
        color: "var(--green)",
        category: "open"
    },
    {
        name: <Trans i18nKey="Progressing"></Trans>,
        slug: "atrisk",
        color: "var(--yellow)",
        category: "open"
    },
    {
        name:<Trans i18nKey="Off Track"></Trans>,
        slug: "offtrack",
        color: "var(--red)",
        category: "open"
    }, {
        name: <Trans i18nKey="Archieved"></Trans>,
        slug: "archieved",
        color: "var(--green)",
        category: "closed"
    }, {
        name: <Trans i18nKey="Partial"></Trans>,
        slug: "partial",
        color: "var(--yellow)",
        category: "closed"
    },
    {
        name: <Trans i18nKey="Missed"></Trans>,
        slug: "missed",
        color: "var(--red)",
        category: "closed"
    },
]

export const getGroupedOKR_Statuses = (containsAllOptions) => {
    const grouped = [];
    const statuses = OKR_Statuses.map(a => ({ label: a.name, value: a.slug, color: a.color, category: a.category }));
    statuses.forEach(status => {
        let category = grouped.find(a => a.label === status.category);
        if (category) {
            category.options.push({ ...status });
        } else {
            const allCategoryOption = { label: "All " + camelCase(status.category) + " Goals", value: "all_" + status.category, color: 'var(--branding)', category: status.category };
            const options = containsAllOptions ? [{ ...allCategoryOption }, { ...status }] : [{ ...status }];
            category = { label: camelCase(status.category), options: options };
            grouped.push(category);
        }
    })
    if (containsAllOptions) {
        grouped.push({ label: "All", options: [{ label: "All Goals", value: "all", color: 'var(--branding)', category: "all" }] })
    }
    return grouped;
};


export const OKR_UPDATE_METHODS = { Automatic: "automatic", Manual: "manual" };

export const OKR_MEASUREMENTS = [
    {
        id: 'percent',
        name:<Trans i18nKey={"Percent"}></Trans>,
        symbol: "%",
    },
    {
        id: 'number',
        name: <Trans i18nKey={"Number"}></Trans>,
        symbol: "#",
    },
];

export const OKR_AFFECTED_BY = {
    ItSelf: "itself",
    Subgoal: "subgoal",
};

export const OKR_PRIVACY = {
    Private: "private",
    Public: "public",
};

export const APPS = {
    Time: { serverName: 'time', frontName: 'time', displayName: 'Time' },
    Organization: { serverName: 'organization', frontName: 'organization', displayName: 'Organization' },
    People: { serverName: 'people', frontName: 'organization', displayName: 'User Management' },
    Account: { serverName: 'account', frontName: 'account', displayName: 'Account' },

    OKR: { serverName: 'goals', frontName: 'goals', displayName: 'OKRs & Goals' },
    Dashboard: { serverName: 'dashboard', frontName: 'dashboard', displayName: 'Dashboard' },
    Init: { serverName: 'init', frontName: 'init', displayName: 'Init' },
}

export const SubscriptionItemStatuses = {
    ScheduleDowngrading: 'scheduleDowngrading',
    Active: 'active'
}
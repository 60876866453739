
import {
    loadCurrentUser, loadMeOrganizations, resetUser
} from "store/actions";

export const loadInitData = (dispatch) => {


    dispatch(loadCurrentUser()).then(() => {

        dispatch(loadMeOrganizations()).then(organizations => {
            if (organizations.payload && organizations.payload.length === 0) {
                if (![
                    "/init",
                    "/account/accountSetting",
                    '/account/changePassword',
                    "/logout",
                    "/login",
                    "/organization-not-found",
                    "/organization-access-denied"
                ].includes(window.location.pathname)) {
                    console.log("GOTO INIT")
                    window.location = "/init"
                }

            }

        })





    });


}

export const removeInitData = async (dispatch) => {
    // await dispatch(resetProjects());
    // await dispatch(resetMembers());
    // await dispatch(resetReports());
    // await dispatch(resetOrganization());
    await dispatch(resetUser());
}


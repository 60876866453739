import React from 'react';
import { Router } from 'react-router-dom';
import history from "./history";
// App Routes
import Routes from './routes/Routes';
// Vendor dependencies
import "./Vendor";
// Application Styles
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import './styles/bootstrap.scss';
import './styles/app.scss'
import './i18n';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { changeSetting, setDefaultLanguage } from './store/actions';
import { useTranslation } from 'react-i18next';
import PageLoader from './components/Common/PageLoader';
import { ErrorBoundary } from 'components/Layout/ErrorBoundary';
// import { ErrorBoundary } from '@sentry/react';

const App = () => {
    const systemDefaultLanguage = useSelector(state => state.serverConfig.config.systemDefaultLanguage);
    let language = useSelector(state => state.user.language);
    language = language ? language : systemDefaultLanguage;
    const dispatch = useDispatch();
    const { i18n, ready } = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(language);
        dispatch(setDefaultLanguage(language))
        dispatch(changeSetting({ name: 'language', value: language }));
    }, [language, dispatch, i18n]);

    useEffect(() => {
        var full = window.location.host;
        var parts = full.split('.');
        var sub = parts[0];
        console.log(sub);
    }, []);

    const basename = "/";
    if (!ready) {
        return <PageLoader></PageLoader>
    }
    return (
        <ErrorBoundary>
            <Router history={history} basename={basename}>
                <Routes />
            </Router>
        </ErrorBoundary>
    );
}

export default App

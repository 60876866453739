import ContactUsForm from 'components/Layout/ContactUsForm';
import { ReactComponent as SupportIcon } from "icons/support-icon.svg";
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

export const AccessDenied = ({ showLinks, resourceName = 'page' }) => {
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
    const history = useHistory();
    return (
        <div className='d-flex align-items-center justify-content-center h-100 w-100 flex-column'>
            <img alt="" src="img/401 Error.svg" className='thumb350' />
            <h2 className='mt-2'>You're not authorized to access this {resourceName}.</h2>
            <div className='d-flex mt-3'>
                {showLinks &&
                    <>
                        <Button color='link' className='m-end-3' onClick={() => { history.push("/") }}>Navigate to Home</Button>

                        <Button color='primary' onClick={() => setIsContactUsOpen(true)}>
                            <SupportIcon color='white' fill='white' />
                            Contact Support
                        </Button>
                    </>
                }
            </div>
            <ContactUsForm
                formType={"support"}
                isOpen={isContactUsOpen}
                setIsOpen={setIsContactUsOpen}
            />
        </div>);
}
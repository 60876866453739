import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';

import {
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import httpService from "../../utils/httpService";
import showToast from "../../utils/toaster";
import InputWithLabel from "components/Common/InputWithLabel";
import { useSelector } from "react-redux";

const ContactUsForm = ({ isOpen, setIsOpen, formType }) => {
    const { t } = useTranslation();
    const user = useSelector(a => a.user.info);
    const [formValue, setFormValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrorMessage("");
    }, [formValue, formType]);

    const handleShowModal = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validator.isEmpty(formValue)) {
            setLoading(true);
            try {
                await httpService.post("/contact-us", {
                    text: formValue,
                    type: formType,
                });

                setFormValue("");
                setErrorMessage("");
                setLoading(false);
                handleShowModal();
                showToast("toast success");
            } catch (error) {
                setLoading(false);
                setErrorMessage(error.message);
                console.log(errorMessage);
            }
        } else {
            setLoading(false);
            showToast("toast error", "error");
        }
    };

    return (
        <>
            <form>
                <Modal
                    size="lg"
                    isOpen={isOpen}
                    toggle={handleShowModal}
                    className="mx-auto mt-5"
                >
                    <ModalHeader className="modal-title text-primary">
                        <div onClick={() => handleShowModal()} >
                            <CloseIcon className="modal-close-button" />
                        </div>
                        <span className="text-capitalize">{t(formType)}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <InputWithLabel
                                    readOnly={true}
                                    label={t('Name')}
                                    type="text" id="name" value={user.name}
                                />
                                <InputWithLabel
                                    readOnly={true}
                                    label={t('Email')}
                                    type="text" id="email" value={user.email}
                                />
                                <Label className="text-primary">{`Message`}</Label>
                                <Input
                                    autoFocus={true}
                                    type="textarea"
                                    rows={10}
                                    onChange={(e) => setFormValue(e.target.value)}
                                    value={formValue}
                                    required
                                ></Input>
                            </div>
                            <div className="col-md-6 col-lg-0">
                                <img src="img/contact-us.svg" alt="" className="thumb350 d-none d-lg-block" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <div className="w-100 d-flex action-box">

                            <Button
                                className="mx-2"
                                color="secondary"
                                onClick={handleShowModal}
                            >
                                {t("cancel")}
                            </Button>
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                color="primary"
                            >
                                {!loading && t("send")}
                                {loading && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                )}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </form>
        </>
    );
};

export default ContactUsForm;

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { OrganizationModel } from 'apps/organization/models/OrganizationModel';
import { OrganizationRequestModel } from 'apps/organization/models/OrganizationRequestModels';
import httpService from "utils/httpService";
const initialState = {
    loading: false,
    currentOrganization: {
        loading: false,
        lastFetch: null,
        entity: {} as OrganizationModel
    }
}


export const createOrganization = createAsyncThunk('organization/create', async (organization: OrganizationRequestModel, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/organizations`, organization)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const uploadOrganizationLogo = createAsyncThunk('organization/uploadLogo', async ({ logo, organizatonId }: { logo: any, organizatonId: number }, { getState }) => {
    const formData = new FormData();
    formData.append('avatar', logo);
    const response = await httpService.post(`/organizations/${organizatonId}/picture`, formData)
    return { photo: response.data.data.photo }
})

const organizationSlice = createSlice({
    name: 'organization',
    initialState,

    reducers: {
    },
    extraReducers: builder => {
        builder
            .addMatcher(isAnyOf(uploadOrganizationLogo.fulfilled), (state, action) => {
                state.currentOrganization.entity.photo = action.payload.photo + "?rand=" + Date.now();
            })
    }
})


export default organizationSlice.reducer

import { useSignupLoginMenu } from 'apps/signupLogin/menu';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeSetting } from 'store/actions';
import { userHasAnyOrganization } from 'store/selectors';
import { APPS } from './components/Common/constants';

import { useAppHelper } from './utils/useAppHelper';
export default function useMenu(permissions) {
    const { currentApp } = useAppHelper();
    const dispatch = useDispatch();
    const signupLoginMenu = useSignupLoginMenu();

    const subMenuIsHidden = useSelector(state => state.settings.subMenuIsHidden);

    const hasAnyOrganization = useSelector(userHasAnyOrganization);
    let Menu = [];
    Menu.push(...signupLoginMenu.getItems())



    //get current app menu items
    if (hasAnyOrganization === false) {
        Menu = Menu.filter(a => a.appName === APPS.Init.frontName);
    } else {
        Menu = Menu.filter(menu => menu.appName === currentApp.frontName && (!menu.permissions || menu.permissions.length === 0 || permissions.some(a => menu.permissions.some(p => p.toLowerCase() === a.toLowerCase()))));
        Menu = Menu.filter(a => !a.hidden);
    }

    const menuLength = Menu.length;
    useEffect(() => {
        const hideSubMenu = menuLength === 0;
        if (hideSubMenu !== subMenuIsHidden) {
            dispatch(changeSetting({ name: 'subMenuIsHidden', value: hideSubMenu }));
        }
    }, [menuLength, subMenuIsHidden, dispatch]);
    return { Menu: Menu };
}

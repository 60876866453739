
import SignupLoginRoutes, { SignupLoginRoutesList, SignupLoginRoutesWithoutSidebar, SignupLoginRoutesWithoutSidebarList } from "apps/signupLogin/routes/SignupLoginRoutes";
import { ErrorBoundary } from "components/Layout/ErrorBoundary";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { PermissionsList } from "../components/Common/constants";

/* loader component for Suspense*/
import PageLoader from "../components/Common/PageLoader";

import Base, { BaseWithoutSidebar } from "../components/Layout/Base";
import BasePage from "../components/Layout/BasePage";

import Layout from "./Layout";
import PrivateRoute, { DefaultRoot } from "./PrivateRoute";
import NotFound from "../components/Layout/NotFound"
import { OrganizationRoutesWithoutSidebar, OrganizationRoutesWithoutSidebarList } from "apps/start/StartRoutes";
const ProfileTabs = lazy(() => import("../components/Profile/ProfileTabs"));


const Notifications = lazy(() =>
    import("../components/Notifications/Notifications")
);
const ViewNotification = lazy(() =>
    import("../components/Notifications/ViewNotification")
);


const Routes = () => {

    const waitFor = (Tag) => (props) => <Tag {...props} />;
    const currentKey = window.location.pathname.split("/")[1] || "/";
    const timeout = { enter: 500, exit: 500 };
    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = "rag-fadeIn";

    const ListOfOnboardingPages = [
        ...SignupLoginRoutesList
    ];
    const mainPages = [
        "",
        "/account/:tab?",
        "/profile",
        "/notifications",
        "/notifications/:id"
    ];

    const withoutLayoutPages = [
    ];
    const withoutSidebarPages = [
        ...SignupLoginRoutesWithoutSidebarList,
        ...OrganizationRoutesWithoutSidebarList
    ];

    return (
        <TransitionGroup>
            <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
            >
                <Switch>
                    <Route path={ListOfOnboardingPages} exact>
                        <Layout layout={BasePage}>
                            <Suspense fallback={<PageLoader />}>
                                <Switch>
                                    <Route path={SignupLoginRoutesList} exact>
                                        <SignupLoginRoutes />
                                    </Route>
                                </Switch>
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route path={mainPages} exact>

                        <Layout layout={Base} isPublic={true}>
                            <Suspense fallback={<PageLoader />}>
                                <ErrorBoundary showLinks={true}>
                                    <Switch>
                                        <PrivateRoute
                                            exact
                                            path="/account/:tab?"
                                            component={waitFor(ProfileTabs)}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/notifications"
                                            component={waitFor(Notifications)}
                                        />
                                        <PrivateRoute
                                            permissions={[PermissionsList.GENERAL_NOTIFICATIONS]}
                                            exact
                                            path="/notifications/:id"
                                            component={waitFor(
                                                ViewNotification
                                            )}
                                        />
                                        <Redirect path="/" to={"/organizations"} />
                                        <Redirect path="/profile" to="/account" />
                                        <DefaultRoot />
                                    </Switch>
                                </ErrorBoundary>
                            </Suspense>

                        </Layout>
                    </Route>
                    <Route path={withoutLayoutPages} exact>
                        <Suspense fallback={<PageLoader />}>
                            <Switch>
                                <DefaultRoot />
                            </Switch>
                        </Suspense>
                    </Route>
                    <Route path={withoutSidebarPages} exact>
                        <Layout layout={BaseWithoutSidebar} isPublic={true}>

                            <Suspense fallback={<PageLoader />}>
                                <Switch>
                                    <Route path={SignupLoginRoutesWithoutSidebarList} exact>
                                        <SignupLoginRoutesWithoutSidebar />
                                    </Route>
                                    <Route path={OrganizationRoutesWithoutSidebarList} exact>
                                        <OrganizationRoutesWithoutSidebar />
                                    </Route>
                                    <DefaultRoot />
                                </Switch>
                            </Suspense>
                        </Layout>
                    </Route>

                    <Route path='*' exact={true} component={() => <NotFound showLinks={true} />} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default Routes;

import { Spinner } from "@blueprintjs/core";
import React from "react";

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = ({ fixInPage = true, size = 40 }) => (
    <div className={fixInPage ? "page-loader" : ""}>
        <Spinner intent="primary" size={size}></Spinner>
    </div>
);

export default PageLoader;

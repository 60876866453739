import React, { lazy } from 'react';
import { Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';

const SelectOrganization = lazy(() =>
    import("./src/SelectOrganization")
);
export const OrganizationRoutesWithoutSidebarList = [
    "/organizations"
]

export const OrganizationRoutesWithoutSidebar = () => {
    const waitFor = (Tag) => (props) => <Tag {...props} />;

    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path="/organizations"
                    component={waitFor(SelectOrganization)}
                />
            </Switch>
        </>
    )
}